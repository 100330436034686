<template>
  <div class="d-flex px-1" :class="{ 'v-event--thick': event.idEvento }">
    <v-icon v-if="event.datos && event.datos.excl" small color="white" :title="event.nombreSalon"
      >mdi-lock</v-icon
    >
     {{ event.name }}
    <v-icon v-if="event.festivo" small right color="white"
      >mdi-party-popper</v-icon
    >
    <v-icon v-if="event.jornada" small right color="white"
      >mdi-timetable</v-icon
    >
    <v-spacer></v-spacer>
    <v-icon color="white" small>{{ event.iconEstadoEvento }}</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
  },
};
</script>

<style>
</style>


<!-- <strong>We're sorry but this site doesn't work properly without JavaScript enabled. Please enable it to continue.</strong><br/><strong>Lo sentimos pero esta página no funciona correctamente sin JavaScript. Por favor, actívalo para continuar.</strong> -->